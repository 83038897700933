import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { Typography, Grid } from "@material-ui/core"
import { makeComponentStyles } from "@components/mixins"
import Button from "@components/Buttons/Button.jsx"
import Modal from "@components/Dialog/Modal.jsx"
import Alert from "@components/Dialog/Alert"
import { FormGroup, FormControl, Form, FormFeedback, Label } from "@components/Forms"
import required from "@rules/required.js"
import email from "@rules/email.js"
import moreThanDate from "@rules/moreThanDate.js"
import rule from "@validator/rule"
import {encodeTimestamp} from "@src/heplers"
import { ARRIVAL_NOTIFICATION } from '@api/modules/requests'
import {useIntl} from "gatsby-plugin-react-intl";



const ModalStyles = ({breakpoints}) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "15px",
  },
  btn_submit: {
    marginTop: 25
  },
  desktop_text: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
  },
  mobile_text: {
    [breakpoints.down("sm")]: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px",
    }
  },
  bold_weight: {
    fontWeight: "500",
  },
})

const initBody = {
  surname: "",
  faculty: "",
  country: "",
  name: "",
  gender: "",
  arrival_date: "",
  started_at: "",
  finished_at: "",
  email: "",
  phone: "",
}

const ModalAirport = ({state, setState}) => {
  const {
    form,
    btn_submit,
    desktop_text,
    mobile_text,
    bold_weight,
  } = makeComponentStyles(ModalStyles)

  const intl = useIntl();
  const [body, setBody] = useState(initBody)
  const [alertState, setAlertState] = useState(false);
  const observeBody = (key, event) => {
    const getTargetValue = () => {
      switch (event.target.type){
        default: return event.target.value
      }
    }
    setBody(body => ({
      ...body,
      [key]: getTargetValue()
    }))
  }

  useEffect(() => {
    if (!alertState) setState(false)
  }, [alertState])

  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      const { name, surname, email, phone, country, faculty, gender, arrival_date, started_at, finished_at, ...rest } = body;
      const formBody = {
        ...rest,
        user: {
          name: name + " " + surname,
          email: email,
          phone: phone,
        },
        main: {
          country: country,
          faculty: faculty,
          gender: gender,
          arrival: {
            arrival_at: encodeTimestamp(arrival_date),
          }
        },
        accommodation: {
          started_at: encodeTimestamp(started_at),
          finished_at: encodeTimestamp(finished_at)
        }
      }
      const { data } = await ARRIVAL_NOTIFICATION(formBody);
      setAlertState(true);
    } catch(err){}
  }

  return (
    <>
      <Modal
        title={intl.formatMessage({id: "notification_form_title"})}
        state={state}
        setState={setState}
        width="md"
      >
        <Form validate onSubmit={handleSubmit} className={form}>
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={clsx(desktop_text, mobile_text, bold_weight)}>{intl.formatMessage({id: "main_info"})}</Typography>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Фамилия >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={"surname-input"}>
                  <Label>{intl.formatMessage({id: "surname"})}</Label>
                  <FormControl
                    onChange={observeBody.bind(null, "surname")}
                    validations={[required]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Факультет >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={'faculty-input'}>
                  <Label>{intl.formatMessage({id: "faculty"})}</Label>
                  <FormControl
                    type={"select"}
                    onChange={observeBody.bind(null, "faculty")}
                    value={body.name}
                    validations={[required]}
                  >
                    <option value="Выберете из списка">{intl.formatMessage({id: "choose_list"})}</option>
                    <option value="Институт прикладной биологии, экологии, почвоведения, сельского и лесного хозяйства">{intl.formatMessage({id: "faculty_name1"})}</option>
                    <option value="Институт военного образования">{intl.formatMessage({id: "faculty_name2"})}</option>
                    <option value="Военный учебный центр">{intl.formatMessage({id: "faculty_name3"})}</option>
                    <option value="Институт искусств и культуры">{intl.formatMessage({id: "faculty_name4"})}</option>
                    <option value="Институт институт экономики и менеджмента">{intl.formatMessage({id: "faculty_name5"})}</option>
                    <option value="Институт прикладной математики и компьютерных наук">{intl.formatMessage({id: "faculty_name6"})}</option>
                    <option value="Высшая IT-школа">{intl.formatMessage({id: "faculty_name7"})}</option>
                    <option value="Юридический институт">{intl.formatMessage({id: "faculty_name8"})}</option>
                    <option value="Геолого-географический факультет">{intl.formatMessage({id: "faculty_name9"})}</option>
                    <option value="Механико-математический факультет">{intl.formatMessage({id: "faculty_name10"})}</option>
                    <option value="Радиофизический факультет">{intl.formatMessage({id: "faculty_name11"})}</option>
                    <option value="Факультет журналистики">{intl.formatMessage({id: "faculty_name12"})}</option>
                    <option value="Факультет иностранных языков">{intl.formatMessage({id: "faculty_name13"})}</option>
                    <option value="Факультет инновационных технологий">{intl.formatMessage({id: "faculty_name14"})}</option>
                    <option value="Факультет исторических и политических наук">{intl.formatMessage({id: "faculty_name15"})}</option>
                    <option value="Факультет психологии">{intl.formatMessage({id: "faculty_name16"})}</option>
                    <option value="Факультет физической культуры">{intl.formatMessage({id: "faculty_name17"})}</option>
                    <option value="Физико-технический факультет">{intl.formatMessage({id: "faculty_name18"})}</option>
                    <option value="Физический факультет">{intl.formatMessage({id: "faculty_name19"})}</option>
                    <option value="Филологический факультет">{intl.formatMessage({id: "faculty_name20"})}</option>
                    <option value="Философский факультет">{intl.formatMessage({id: "faculty_name21"})}</option>
                    <option value="Химический факультет">{intl.formatMessage({id: "faculty_name22"})}</option>
                  </FormControl>
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Страна >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={"country-input"}>
                  <Label>{intl.formatMessage({id: "country"})}</Label>
                  <FormControl
                    onChange={observeBody.bind(null, "country")}
                    validations={[required]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Имя >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={"name-input"}>
                  <Label>{intl.formatMessage({id: "name"})}</Label>
                  <FormControl
                    onChange={observeBody.bind(null, "name")}
                    validations={[required]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Пол >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={'gender-input'}>
                  <Label>{intl.formatMessage({id: "gender"})}</Label>
                  <FormControl
                    type={"select"}
                    onChange={observeBody.bind(null, "gender")}
                    validations={[required]}
                  >
                    <option value="Выберите из списка">{intl.formatMessage({id: "choose_list"})}</option>
                    <option value="male">{intl.formatMessage({id: "gender_male"})}</option>
                    <option value="female">{intl.formatMessage({id: "gender_female"})}</option>
                  </FormControl>
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Дата прибытия >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={"arrival-date-input"}>
                  <Label>{intl.formatMessage({id: "arrival_date"})}</Label>
                  <FormControl
                    type="date"
                    onChange={observeBody.bind(null, "arrival_date")}
                    validations={[
                      required,
                      rule(moreThanDate, { value: 'now', message: intl.formatMessage({id: "date_err"}) })
                    ]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={clsx(desktop_text, mobile_text, bold_weight)}>{intl.formatMessage({id: "arrival_length"})}</Typography>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Начало >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={6}>
                <FormGroup name={'started-input'}>
                  <Label>{intl.formatMessage({id: "arrival_start"})}</Label>
                  <FormControl
                    type={"date"}
                    onChange={observeBody.bind(null, 'started_at')}
                    validations={[
                      required,
                      rule(moreThanDate, { value: 'now', message: intl.formatMessage({id: "date_err"}) })
                    ]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Конец >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={6}>
                <FormGroup name={'finished-input'}>
                  <Label>{intl.formatMessage({id: "arrival_finish"})}</Label>
                  <FormControl
                    type={"date"}
                    onChange={observeBody.bind(null, 'finished_at')}
                    validations={[
                      required,
                      rule(moreThanDate, { value: encodeTimestamp(body.started_at), message: intl.formatMessage({id: "arrival_finish_err"}) })
                    ]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/* пустой блок, чтобы не ехали элементы выше */}
              <Grid item md={4} xs={0} />
              {/*<<<<<<<<<<<<<<<< Email >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={"email-input"}>
                  <Label>{intl.formatMessage({id: "email_h"})}</Label>
                  <FormControl
                    type="email"
                    onChange={observeBody.bind(null, "email")}
                    validations={[required, email]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
              {/*<<<<<<<<<<<<<<<< Номер телефона >>>>>>>>>>>>>>>>>>>>>*/}
              <Grid item md={4} xs={12}>
                <FormGroup name={"phone-input"}>
                  <Label>{intl.formatMessage({id: "phone_num"})}</Label>
                  <FormControl
                    type="tel"
                    mask={"+7(999)-999-99-99"}
                    onChange={observeBody.bind(null, "phone")}
                    validations={[required]}
                  />
                  <FormFeedback/>
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Button type="submit" color={"primary"} className={btn_submit}>{intl.formatMessage({id: "send"})}</Button>
        </Form>
      </Modal>
      <Alert
        state={alertState}
        setState={setAlertState}
      >
        {intl.formatMessage({id: "application_send"})}
      </Alert>
    </>
  )
}

export default ModalAirport
