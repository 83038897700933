import React, { useContext, useState } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "@components/mixins"
import Container from "@components/Container/Container.jsx"
import Button from "@components/Buttons/Button.jsx";
import MainWrap from "@components/Wrap/MainWrap"
import Chip from "@components/Cards/Chip.jsx"
import { Box, Typography, Grid, Avatar } from "@material-ui/core"
import infoLabel from "@static/images/Info.svg"
import ModalAirport from "./Modals/ModalAirport"
import ModalArrival from "./Modals/ModalArrival"
import { graphql, useStaticQuery } from "gatsby"
import { getStrapiContentByLang } from "../../heplers.js"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import Markdown from "markdown-to-jsx"

const styles = ({ palette, breakpoints }) => ({
  // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
  important__header: {
    padding: "30px 0",
    [breakpoints.down("md")]: {
      paddingBottom: "16px",
    },
  },
  important__chip: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "20px",
    lineHeight: "24px",
    width: "180px",
    height: "48px",
    [breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "17px",
      width: "134px",
      height: "40px",
    },
  },
  important__container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "80px",
    [breakpoints.down("md")]: {
      paddingBottom: "20px",
    }
  },
  important__text: {
    fontSize: "20px",
    lineHeight: "24px",
  },
  important__text_box: {
    backgroundColor: palette.primary.light,
    color: palette.text.success,
    width: "80%",
    // height: "200px",
    padding: "52px 36px",
    [breakpoints.down("md")]: {
      padding: "25px 22px",
      width: "90%",
    }
  },
  // Формы
  block_padding: {
    paddingBottom: "100px",
    [breakpoints.down("sm")]: {
      paddingBottom: "0px",
    }
  },
  form: {
    paddingBottom: "50px",
    [breakpoints.down("md")]: {
      padding: "30",
    }
  },
  form__header: {
    fontSize: "22px",
    lineHeight: "26px",
    paddingBottom: "30px",
    [breakpoints.down("sm")]: {
      paddingBottom: "12px",
    },
  },
  form__box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form__text: {
    paddingBottom: "55px",
    width: "80%",
    [breakpoints.down("md")]: {
      width: "100%",
      paddingBottom: "30px",
    }
  },
  form__btn: {
    width: "182px",
    height: "50px",
    fontSize: "18px !important",
    lineHeight: "21px !important",
    fontWeight: "normal !important",
  },
  // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
  desktop_text: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
  },
  mobile_text: {
    [breakpoints.down("sm")]: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px"
    }
  },
  bold_weight: {
    fontWeight: "500",
  }
})

const Arrival = () => {
  const {
    // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
    important__header,
    important__chip,
    important__container,
    important__text,
    important__text_box,
    // <<<<<<<<<<<<<<<< Блок Формы  >>>>>>>>>>>>>>>>>>>>>
    block_padding,
    form,
    form__header,
    form__box,
    form__text,
    form__btn,
    // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
    desktop_text,
    mobile_text,
    bold_weight,
  } = makeComponentStyles(styles);

  const Intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      allStrapiArrival {
        edges {
          node {
            locale
            NotificationAboutArrivalText
            MeetInAirportText
            ImportantText
          }
        }
      }
    }
  `)

  const {
    NotificationAboutArrivalText,
    MeetInAirportText,
    ImportantText,
  } = getStrapiContentByLang(data.allStrapiArrival.edges, Intl.locale)

  const [modalAirportState, setModalAirportState] = useState(false);
  const [modalArrivalState, setModalArrivalState] = useState(false);

  return (
    <>
      {/*<<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>*/}
      <Container mw={"lg"}>
        <MainWrap>
          <MainWrap.Header>
            <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
              {Intl.formatMessage({ id: "arrival" })}
            </Typography>
          </MainWrap.Header>
          <MainWrap.Body>
            <Grid container>
              <Grid className={important__header} item xs={12}>
                <Chip avatar={<Avatar src={infoLabel} />}
                      label={Intl.formatMessage({ id: "important" })}
                      straight
                      className={clsx(important__chip, bold_weight)}>
                </Chip>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={important__container}>
                  <Box className={important__text_box}>
                    <Typography className={clsx(important__text, mobile_text, bold_weight)}>
                      <Markdown>{ImportantText}</Markdown>
                    </Typography>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </MainWrap.Body>
        </MainWrap>
      </Container>
      {/*<<<<<<<<<<<<<<<< Блок форма >>>>>>>>>>>>>>>>>>>>>*/}
      <Container mw={"lg"} className={block_padding}>
        <Container className={form}>
          <Typography className={clsx(form__header, bold_weight)}>{Intl.formatMessage({ id: "arrival_form_title" })}</Typography>
          <Box className={form__box}>
            <Typography className={clsx(form__text, desktop_text, mobile_text)}>
              <Markdown>{MeetInAirportText}</Markdown>
            </Typography>
            <Button className={form__btn} onClick={() => setModalAirportState(true)} style={{padding: "5px"}} type={"primary"}>{Intl.formatMessage({ id: "write_form_btn"})}</Button>
          </Box>
        </Container>
        <ModalAirport state={modalAirportState} setState={setModalAirportState}/>

        <Container className={form}>
          <Typography className={clsx(form__header, bold_weight)}>{Intl.formatMessage({ id: "notification_form_title" })}</Typography>
          <Box className={form__box}>
            <Typography className={clsx(form__text, desktop_text, mobile_text)}>
              <Markdown>{NotificationAboutArrivalText}</Markdown>
            </Typography>
            <Button className={form__btn} style={{padding: "5px"}}  onClick={() => setModalArrivalState(true)} type={"primary"} >{Intl.formatMessage({ id: "write_form_btn"})}</Button>
          </Box>
        </Container>
        <ModalArrival state={modalArrivalState} setState={setModalArrivalState}/>
      </Container>
    </>
  )
}

export default Arrival;
