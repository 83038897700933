import React from 'react'
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from "@material-ui/core"
import CloseIcon from '@static/images/CloseSquare.svg';
import IconButton from "@components/Buttons/IconButton.jsx"
import { makeComponentStyles } from "../mixins.js"
import clsx from "clsx"

const styles = ({ breakpoints }) => ({
  dialog__header: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingTop: 15,
    [breakpoints.down('sm')]: {
      justifyContent: "flex-start",
    }
  },
  dialog__button_close: {
    position: "absolute",
    top: 0,
    right: 0
  }
})


function Modal({ title, children, state, setState, width = "sm"}){

  const {
    dialog__header,
    dialog__button_close
  } = makeComponentStyles(styles);
  const isDesktop = useMediaQuery(theme => theme.breakpoints.down('sm'), {noSsr: true});
  const handleClose = () => {
    setState(false)
  }

  const props = {
    width,
  }
  return (
    <>
      <Dialog {...props} fullScreen={isDesktop} maxWidth={width} fullWidth={true} open={state} onClose={handleClose}>
        <div className={dialog__header}>
          <IconButton color={"basic"} onClick={handleClose} className={dialog__button_close}>
            <img src={CloseIcon} alt="close" />
          </IconButton>
          <DialogTitle>{title}</DialogTitle>
        </div>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Modal;
