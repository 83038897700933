import React from 'react'
import { Chip as MuiChip } from "@material-ui/core";
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx"

const styles = ({ palette }) => ({
    root: {
        '& $div': {
            background: `${palette.primary.main} !important`
        },
        '& .MuiChip-label' : {
            overflow: 'visible',
        }
    },
    straight: {
        borderRadius: 0,
        height: 'auto',
        padding: '12px 65px 12px 5px'
    }
});
function Chip({ avatar, label, color = "primary", straight=false, className = "", ...rest }) {
    const classes = makeComponentStyles(styles)
    const props = {
        avatar,
        label,
        color,
        className: clsx(classes.root, straight ? classes.straight : '',  className),
        ...rest
    }
    return (
        <MuiChip {...props} />
    )
}

export default Chip