import React from 'react';
import {Dialog, DialogContent, DialogContentText} from "@material-ui/core";
import SuccessIcon from "@static/images/SuccessIcon.svg";
import {makeComponentStyles} from "../mixins";

const styles = () => ({
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    content__text: {
        margin: "15px 0",
        textAlign: "center"
    }
})

function Alert({ children, state, setState }){

    const {
        content,
        content__text
    } = makeComponentStyles(styles);

    const handleClose = () => {
        setState(false)
    }

    return (
        <>
            <Dialog maxWidth={"xs"} fullWidth={true} open={state} onClose={handleClose} onClick={handleClose}>
                <DialogContent className={content}>
                    <img src={SuccessIcon} alt="success-icon"/>
                    <DialogContentText className={content__text}>
                        { children }
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Alert;
