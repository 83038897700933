class Rule{
    constructor({ rule, args }) {
        this.rule = rule;
        this.args = args;
    }
    passes(_, value){
        return this.rule.passes(this.args,  value);
    }
    message(){
        return this.rule.message(this.args.message || {});
    }
}

const rule = (rule, args) => new Rule({ rule, args });

export default rule;
