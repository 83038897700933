import React, { useState, useEffect, useContext } from "react"
import Layout from "@components/Layout/Layout";
import { makeComponentStyles } from "@components/mixins"
import Arrival from "@components/Arrival/Arrival"
import Seo from "@components/seo"
import { getStrapiContentByLang } from "../heplers"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { graphql } from "gatsby"

const styles = () => ({
  indexLayout: {
    "& > main":{
      paddingBottom: 0
    }
  }
})

const ArrivalPage = ({data}) => {
  const { indexLayout } = makeComponentStyles(styles);

  const { language } = useContext(IntlContextConsumer);
  const intl = useIntl();

  const {
    ArrivalSEO
  } = getStrapiContentByLang(data.allStrapiArrival.edges, language);


  return (
    <div>
      <Seo title={ArrivalSEO?.TitleSEO}
           description={ArrivalSEO?.DescriptionSEO}
           keywords={ArrivalSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout className={indexLayout}>
          <Arrival />
        </Layout>
      </>
    </div>
  )
}

export default ArrivalPage;

export const pageQuery = graphql`{
  allStrapiArrival {
    edges {
      node {
        locale
        ArrivalSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`;