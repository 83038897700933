import {encodeTimestamp} from "../../heplers";

const parseDateNow = () => {
    const now = new Date();
    return (new Date(now.getFullYear(), now.getMonth(), now.getDate())).getTime() / 1000;
}

export default {
    passes(args, value){
        if(args.value === "now") args.value = parseDateNow();
        return encodeTimestamp(value) >= args.value;
    },
    message(message){
        return message;
    }
}
